import * as React from "react"
import styled from "styled-components";


import Layout from "../components/layout"
import Seo from "../components/seo"
import Title from '../components/Title';
import Image from '../components/Image';

const Box = styled.div`
  @media(max-width: 1000px){
    padding: 0 20px;
  }
`;

const Container = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  text-align: justify;
  align-items: center;

  p{
    margin-right:20px;
  }
  h3 {
    margin-left: 10px;
  }
  img{
    height: 31px;
    width: 31px;
  }

  @media(max-width: 1000px){
    flex-direction: column;
    align-items: center;

    img{
      margin-top:20px;
      width:80%;
    }
  }
`;

const IndexPage = () => (
  <Layout>
    <Seo title="Nuestra Empresa" />
    <Box>
      <Title title='Nuestra' secondTitle='Empresa' />
  
      <p>Es una empresa dedicada a entregar soluciones. Hasta ahora se ha centrado prioritariamente en el campo de las cadenas de negocios, con experiencias y capacidad para efectuar trabajos de cualquier nivel y en cualquier ámbito.
        <br />Ha desarrollado sus actividades en óptimas condiciones, desde el diseño, fabricación y mantención, también hemos desarrollando trabajos en el área de obras civiles menores como son la construcción, reparación y montaje de cortinas metálicas, pintura, gasfitería, electricidad y mantención de obras civiles. 
      </p>
     
      <Container>
        <Image srcName="tit_compromiso.png" width="31px" height="31px" alt="Certificacion NCH2769 NCH2770" />
        <h3 class="featurette-SubTitulo">Nuestro Compromiso</h3>
      </Container>
      <p>Con una fuerte vocación de servicio y orientación al cliente, ESOV se ha caracterizado permanentemente por su flexibilidad y adaptación a las necesidades de nuestros clientes, intentando crear el máximo valor para los mismos, tanto en la fase de ejecución y montaje, Para ello nuestra empresa intenta garantizar el optimo resultado a todas sus necesidades ya que  cuenta con un equipo profesional con la capacitación necesaria y el compromiso de ofrecer el mejor servicio a un mercado que reclama eficiencia en la relación cliente-empresa.</p> 

      <Container>
        <Image srcName="tit_mision.png" width="31px" height="31px" alt="Certificacion NCH2769 NCH2770" />
        <h3 class="featurette-SubTitulo">Nuestra Misión</h3>
      </Container>
      <p>ESOV esta proyectado a contribuir y apoyar al éxito de nuestros clientes  implementando soluciones que aporten un alto valor y lograr así su satisfacción a lo largo del tiempo, mejorando así nuestra ventaja competitiva.</p> 

      <Container>
        <Image srcName="tit_valor.png" width="31px" height="31px" alt="Certificacion NCH2769 NCH2770" />
        <h3 class="featurette-SubTitulo">Nuestro Valor</h3>
      </Container>
      <p>Es el trabajo en equipo interactuando con el cliente, identificándonos con sus objetivos y estableciendo una relación personalizada basada en el compromiso, el profesionalismo, la confianza y la calidad de nuestro trabajo; brindando nuestros conocimientos y experiencia al logro del fin previsto anticipándonos a las necesidades y respondiendo de forma rápida y eficaz a las necesidades del cliente.</p> 
    
    </Box>
  </Layout>
)

export default IndexPage
